import api from '@/app/api'

export default {
  async getReviews() {
    const response = await api.get('/review')
    return response
  },

  async createReview(reviewData) {
    const response = await api.post('/review', reviewData)
    return response
  },

  async getReviewById(id) {
    const response = await api.get(`/review/${id}`)
    return response
  },

  async editReview(id, reviewData) {
    const response = await api.put(`/review/${id}`, reviewData)
    return response
  },

  async deleteReview(id) {
    const response = await api.delete(`/review/${id}`)
    return response
  },
}
